<i *ngIf="icon && isIcon" [class]="icon"></i>
<img *ngIf="src && isImg" [src]="src" [attr.alt]="alt" />
<div
  class="h-100 d-flex justify-content-center align-items-center"
  *ngIf="text && isText"
  [ngStyle]="background"
>
  <span class="avatar-text d-inline-block" #textEl [ngStyle]="textStyles">{{
    text
  }}</span>
</div>
