import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { User } from '@app/shared/types/auth.interface';

@Component({
    selector: 'nav-profile',
    templateUrl: './nav-profile.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.header-nav-item]': 'true'
    }
})
export class NavProfileComponent implements OnInit {
    currentUser: User
    constructor(private authService: AuthService,private cdr:ChangeDetectorRef) { }

    profileMenuList = [
        {
            path: 'settings/',
            icon: 'feather icon-user',
            item: 'Profile'
        },
        {
            path: '/settings/personal/security',
            icon: 'feather icon-lock',
            item: 'Change password'
        },
        {
            path: 'settings/organization/profile',
            icon: 'feather icon-settings',
            item: 'Organization settings'
        },
        {
            path: '',
            icon: 'feather icon-power',
            item: 'Sign Out'
        }
    ]

    ngOnInit(): void {
      this.authService.getCurrentUser().subscribe()
      this.authService.currentUser$.subscribe({
        next:(user)=>{
          if(user?.email){
            this.currentUser = user;
            if(this.currentUser?.role != 'Admin'){
              this.profileMenuList = this.profileMenuList.filter(val => val.item != "Organization settings");
            }
            this.cdr.detectChanges();
          }
        }
      })
    }

    signOut() {
      this.authService.logout()
    }
}
