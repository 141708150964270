import { Routes } from '@angular/router';
import { AuthGuard } from '@app/shared/guards/auth.guard';
import { OldQuotationListComponent } from '@app/views/quotations/old-quotation-list/old-quotation-list.component';

export const APP_LAYOUT_ROUTES: Routes = [
    //Dashboard
    {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('../views/dashboard/dashboard.module').then(m => m.DashboardModule),
    },
    {
        path: 'items',
        canActivate: [AuthGuard],
        loadChildren: () => import('../views/items/items.module').then(m => m.ItemsModule),
    },
    {
        path: 'customers',
        canActivate: [AuthGuard],
        loadChildren: () => import('../views/customers/customers.module').then(m => m.CustomersModule),
    },
    {
        path: 'quotations',
        canActivate: [AuthGuard],
        loadChildren: () => import('../views/quotations/quotations.module').then(m => m.QuotationsModule),
    },
    {
        path: 'old-quotations/list',
        component: OldQuotationListComponent
    },
    {
        path: 'invoices',
        canActivate: [AuthGuard],
        loadChildren: () => import('../views/invoices/invoices.module').then(m => m.InvoicesModule),
    },
    {
        path: 'reports',
        canActivate: [AuthGuard],
        loadChildren: () => import('../views/reports/reports.module').then(m => m.ReportsModule),
    },
    {
        path: 'settings',
        canActivate: [AuthGuard],
        loadChildren: () => import('../views/settings/settings.module').then(m => m.SettingsModule),
    }
    
];
