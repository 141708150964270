import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class httpInterceptor implements HttpInterceptor {
  constructor(private router: Router) { }

  /**
   * If the request has a token, add it to the header. If the request fails, remove the token and
   * redirect to the login page
   * @param request - HttpRequest<unknown> - The request object
   * @param {HttpHandler} next - HttpHandler - The next interceptor in the chain.
   * @returns Observable<HttpEvent<unknown>>
   */
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let token = localStorage.getItem('access-token')
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            localStorage.removeItem('access-token');
            this.router.navigate(['/auth/login']);
          }
        }
        return throwError(() => new Error(err?.error?.message || 'Error occurred!'));
      })
    );
  }
}
