export const nav = {
    'NAV': {
        'DASHBOARD': 'Dashboard',
        'ITEMS':'Items',
        'CUSTOMERS':'Customers',
        'QUOTATIONS':'Quotations',
        'OLD_QUOTATIONS':'Old-Quotations',
        'INVOICES':'Invoices',
        'ADMINISTRATION':'Administration',
        'SETTINGS':'Settings',
        'USERS':'Users',
        'REPORTS':'Reports',
        'MENU_WITH_TITLE': 'Menu with title',
        'MENU_WITH_TITLE_ITEM_1': 'Menu with title item 1',
        'MENU_WITH_TITLE_ITEM_2': 'Menu with title item 2',
        'NAV_WITH_SUBMENU': 'Nav with submenu',
        'SUBMENU_1': 'Submenu 1',
        'SUBMENU_2': 'Submenu 2',
        'SUBMENU_WITH_TITLE': 'Submenu with title',
        'MENU': 'Menu'
    }
}