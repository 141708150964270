import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.scss'],
})
export class ConfirmationBoxComponent implements OnInit {
  getUrl = [];
  onClose!: Function;
  title!: string;
  type!: any;
  message!: string;
  closeBtnName!: string;
  submitBtnName!: string;
  forInvoice!: boolean;
  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit(): void {}
  onSubmit() {
    this.onClose(true);
  }
}
