import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';

type ShapeType = 'circle' | 'square';
type SizeType = 'large' | 'default' | 'small';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.avatar]': 'true',
    '[class.avatar-lg]': `size === 'large'`,
    '[class.avatar-sm]': `size === 'small'`,
    '[class.avatar-square]': `shape === 'square'`,
    '[class.avatar-circle]': `shape === 'circle'`,
    '[class.avatar-icon]': `icon`,
    '[class.avatar-image]': `isImg `,
    '[style.width]': 'customSize',
    '[style.height]': 'customSize',
    '[style.line-height]': 'customSize',
    '[style.font-size.px]': '(isIcon && customSize) ? $any(size) / 2 : null',
  },
})
export class AvatarComponent implements OnInit, AfterViewInit {
  @Input() shape: ShapeType = 'circle';
  @Input() size: SizeType | number = 'default';
  @Input() text?: string;
  @Input() src?: string;
  @Input() alt?: string;
  @Input() icon?: string;

  isText: boolean = false;
  isImg: boolean = true;
  isIcon: boolean = false;
  textStyles = {};
  customSize: string | null = null;
  background = {};
  @ViewChild('textEl', { static: false }) textEl?: ElementRef;
  private el: HTMLElement = this.elementRef.nativeElement;

  constructor(private cdr: ChangeDetectorRef, private elementRef: ElementRef) {}
  ngAfterViewInit(): void {
    this.calcStringSize();
    this.cdr.detectChanges();
  }

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.isText = !this.src && !!this.text;
    this.isIcon = !this.src && !!this.icon;
    this.isImg = !!this.src;
    if (this.isText) {
      this.trimText();
    }
    this.setSizeStyle();
    this.calcStringSize();
  }

  private setSizeStyle(): void {
    if (typeof this.size === 'number') {
      this.customSize = `${this.size}px`;
    } else {
      this.customSize = null;
    }
    this.cdr.detectChanges();
  }

  private calcStringSize(): void {
    if (!this.isText) {
      return;
    }
    if (this.textEl) {
      const childrenWidth = this.textEl!.nativeElement.offsetWidth;
      const avatarWidth = this.el.getBoundingClientRect().width;
      const scale =
        avatarWidth - 16 > childrenWidth
          ? (avatarWidth - 18) / childrenWidth
          : 1;
      let hash = this.text.split('')[0].charCodeAt(0);
      hash =
        this.text.split('')[0].charCodeAt(0) +
        this.text.split('')[1].charCodeAt(0) +
        (hash << 5);

      const color = `rgba(${Math.abs(hash % 256)}, ${Math.abs(
        (hash >> 2) % 256
      )}, ${Math.abs((hash >> 8) % 256)}, 0.6)`;

      this.textStyles = {
        transform: `scale(${scale})`,
      };
      this.background = {
        backgroundColor: color,
      };
      if (this.customSize) {
        Object.assign(this.textStyles, {
          lineHeight: this.customSize,
        });
      }
      this.cdr.detectChanges();
    }
  }
  trimText() {
    let arr = [];
    arr = this.text.split(' ');
    this.text = arr[0][0] + arr[1][0];
    this.cdr.detectChanges();
  }
}
