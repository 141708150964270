import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) { }

  // Set the API URL from the environment configuration
  API_URL: string = environment.API_URL;

  /**
   * Makes a POST request with optional query data
   * @param url The endpoint to send the request to
   * @param data The data to include in the request body
   * @param queryData Optional query parameters to include in the request URL
   * @returns An observable that emits the response data when the request completes
   */
  post(url: string, data: any, queryData?: any) {
    // Append the query data to the URL if it is provided
    url = queryData ? this.convertToQueryParams(url, queryData) : url;
    // Send the POST request using the HttpClient service
    return this.http.post(this.API_URL + url, data);
  }

  /**
   * Makes a GET request with optional query data
   * @param url The endpoint to send the request to
   * @param queryData Optional query parameters to include in the request URL
   * @returns An observable that emits the response data when the request completes
   */
  get(url: string, queryData?: any) {
    // Append the query data to the URL if it is provided
    url = queryData ? this.convertToQueryParams(url, queryData) : url;
    // Send the GET request using the HttpClient service
    return this.http.get(this.API_URL + url);
  }

  /**
   * Makes a DELETE request with optional query data
   * @param url The endpoint to send the request to
   * @param queryData Optional query parameters to include in the request URL
   * @returns An observable that emits the response data when the request completes
   */
  delete(url: string, queryData?: any) {
    // Append the query data to the URL if it is provided
    url = queryData ? this.convertToQueryParams(url, queryData) : url;
    // Send the DELETE request using the HttpClient service
    return this.http.delete(this.API_URL + url);
  }

  /**
   * Makes a PUT request with optional query data
   * @param url The endpoint to send the request to
   * @param data The data to include in the request body
   * @param queryData Optional query parameters to include in the request URL
   * @returns An observable that emits the response data when the request completes
   */
  put(url: string, data: any, queryData?: any) {
    // Append the query data to the URL if it is provided
    url = queryData ? this.convertToQueryParams(url, queryData) : url;
    // Send the PUT request using the HttpClient service
    return this.http.put(this.API_URL + url, data);
  }


  /**
     * Makes a PATCH request with optional query data
     * @param url The endpoint to send the request to
     * @param data The data to include in the request body
     * @param queryData Optional query parameters to include in the request URL
     * @returns An observable that emits the response data when the request completes
     */
  patch(url: string, data: any, queryData?: any) {
    // If there are query parameters, append them to the URL using the addQueries function
    url = queryData ? this.convertToQueryParams(url, queryData) : url;
    // Make the PATCH request using the http object and return the result
    return this.http.patch(this.API_URL + url, data);
  }

  /**
   * Convert object into query params
   * @param url The endpoint to send the request to
   * @param queryData Optional query parameters to include in the request URL
   * @returns An observable that emits the response data when the request completes
   */
  convertToQueryParams(url: string, queryData: any) {
    // Add a "?" to the end of the URL to start the query parameters
    url += '?';
    let i = 1;
    let key: any, value: any;
    // Loop through each key-value pair in the queryData object and append them to the URL
    for ([key, value] of Object.entries(queryData)) {
      url += `${key}=${encodeURIComponent(value)}`;
      // If there are more key-value pairs to add, append an "&" to separate them
      if (i !== Object.keys(queryData).length) {
        url += '&';
      }
      i++;
    }
    // Return the updated URL with the appended query parameters
    return url;
  }
}
