<dropdown
  dropdownClass="header-nav-item-select nav-profile"
  (click)="navProfile.toggle($event)"
>
  <div class="toggle-wrapper d-flex align-items-center">
    <avatar
      [size]="35"
      [src]="currentUser?.avatar"
      [text]="currentUser?.firstName + ' ' + currentUser?.lastName"
    ></avatar>
    <span class="fw-bold mx-1"
      >{{ currentUser?.firstName }}&nbsp;{{ currentUser?.lastName }}</span
    >
    <i class="feather icon-chevron-down"></i>
  </div>
  <dropdown-menu #navProfile alignment="right">
    <div class="nav-profile-header">
      <div class="d-flex align-items-center">
        <avatar
          [src]="currentUser?.avatar"
          [text]="currentUser?.firstName + ' ' + currentUser?.lastName"
        ></avatar>
        <div class="d-flex flex-column ms-1">
          <span class="fw-bold text-dark"
            >{{ currentUser?.firstName }}&nbsp;{{ currentUser?.lastName }}</span
          >
          <span class="font-size-sm">{{ currentUser?.email }}</span>
        </div>
      </div>
    </div>
    <a
      routerLink="{{ menuItem.path }}"
      dropdownItem
      *ngFor="let menuItem of profileMenuList"
      (click)="menuItem.item === 'Sign Out' ? signOut() : ''"
    >
      <div class="d-flex align-items-center gap-2">
        <i class="font-size-lg" [ngClass]="menuItem.icon"></i>
        <span>{{ menuItem.item }}</span>
      </div>
    </a>
  </dropdown-menu>
</dropdown>
