import { Routes } from '@angular/router';
import { NoAuthGuard } from '@app/shared/guards/no-auth.guard';

export const AUTH_LAYOUT_ROUTES: Routes = [
    {
        path: 'auth',
        canActivate: [NoAuthGuard],
        loadChildren: () => import('../views/auth/auth.module').then(m => m.AuthModule),
    },
    {
        path: 'error',
        loadChildren: () => import('../views/errors/errors.module').then(m => m.ErrorsModule),
    }
];
