<div class="nav-logo">
    <logo 
        [logoType]="!collapse || quickExpand ? 'logo' : 'fold' "
        [height]="!collapse || quickExpand ? 70 : 40"
        [white]= "color === 'dark'"
        class="w-100" style="margin-top: 40px;"
    ></logo>
</div>
<div class="logo-tagline">Simplifying technology, redefining value</div>
<perfect-scrollbar class="side-nav-content" style="height: unset;">
    <vertical-menu-content></vertical-menu-content>
</perfect-scrollbar>
