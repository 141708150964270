import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class NoAuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  /**
   * If the URL contains /auth/login and the user has a token, redirect to the AFTER_LOGIN_URL
   * @param {ActivatedRouteSnapshot} route - ActivatedRouteSnapshot - The route that is being
   * activated.
   * @param {RouterStateSnapshot} state - RouterStateSnapshot
   * @returns A boolean value.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const token = this.authService.getAccessToken();
    if (state.url.toString().includes('/auth/login') && token) {
      this.router.navigate([environment.AFTER_LOGIN_URL])
    }

    return true
  }
}
