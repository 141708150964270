export const nav = {
    'NAV': {
        'DASHBOARD': 'Tableau De Board',
        'ITEMS':'Articles',
        'CUSTOMERS':'Clientes',
        'QUOTATIONS':'Citations',
        'INVOICES':'Invoices',
        'USERS':'Users',
        'SETTINGS':'Settings',
        'REPORTS':'Rapports',

        'MENU_WITH_TITLE': 'Menu avec titre',
        'MENU_WITH_TITLE_ITEM_1': 'Menu avec élément de titre 1',
        'MENU_WITH_TITLE_ITEM_2': 'Menu avec élément de titre 2',
        'NAV_WITH_SUBMENU': 'Nav avec sous-menu',
        'SUBMENU_1': 'Sous-menu 1',
        'SUBMENU_2': 'Sous-menu 2',
        'SUBMENU_WITH_TITLE': 'Sous-menu avec titre',
        'MENU': 'Menu'
    }
}