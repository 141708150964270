import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService) { }

  /**
   * If the user is logged in, return true. If the user is not logged in, log them out and return false
   * @param {ActivatedRouteSnapshot} route - ActivatedRouteSnapshot
   * @param {RouterStateSnapshot} state - RouterStateSnapshot
   * @returns A boolean value.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this.authService.getAccessToken();

    if (token) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.authService.logout();
    return false;
  }
}
