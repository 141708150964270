import { NavMenu } from '@app/shared/types/nav-menu.interface';

const dashboard: NavMenu[] = [
    {
        path: '/dashboard',
        title: 'Dashboard',
        translateKey: 'NAV.DASHBOARD',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-home',
        key: 'dashboard',
        submenu: []
    },
]

const items: NavMenu[] = [
    {
        path: '/items',
        title: 'Items',
        translateKey: 'NAV.ITEMS',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-shopping-bag',
        key: 'items',
        submenu: []
    },
]

const customers: NavMenu[] = [
    {
        path: '/customers',
        title: 'Customers',
        translateKey: 'NAV.CUSTOMERS',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-user',
        key: 'customers',
        submenu: []
    },
]

const quotations: NavMenu[] = [
    {
        path: '/quotations',
        title: 'Quotations',
        translateKey: 'NAV.QUOTATIONS',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-feather',
        key: 'quotations',
        submenu: []
    }
]

const old_quotations: NavMenu[] = [
    {
        path: '/old-quotations/list',
        title: 'Old Quotations',
        translateKey: 'NAV.OLD_QUOTATIONS',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-layers',
        key: 'old-quotations',
        submenu: []
    }
]

const invoices: NavMenu[] = [
    {
        path: '/invoices',
        title: 'Invoices',
        translateKey: 'NAV.INVOICES',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-file-minus',
        key: 'invoices',
        submenu: []
    }
]

const administration: NavMenu[] = [
    {
        path: '',
        title: 'Administration',
        translateKey: 'NAV.ADMINISTRATION',
        type: 'title',
        iconType: 'feather',
        icon: 'icon-file',
        key: 'administration',
        submenu: [
            {
                path: '/reports',
                title: 'Reports',
                translateKey: 'NAV.REPORTS',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-bar-chart-line',
                key: 'administration.reports',
                submenu: []
            },
            {
                path: '/settings/organization/users',
                title: 'Users',
                translateKey: 'NAV.USERS',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-users',
                key: 'administration.users',
                submenu: []
            }, {
                path: '/settings/organization/profile',
                title: 'Settings',
                translateKey: 'NAV.SETTINGS',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-settings',
                key: 'administration.settings',
                submenu: []
            }
        ]
    }
]



// const menuWithTitle: NavMenu[] = [
//     {
//         path: '',
//         title: 'Menu with Title',
//         translateKey: 'NAV.MENU_WITH_TITLE',
//         type: 'title',
//         iconType: 'feather',
//         icon: 'icon-file',
//         key: 'menu-with-title',
//         submenu: [
//             {
//                 path: '/menu-1',
//                 title: 'Menu 1',
//                 translateKey: 'NAV.MENU_WITH_TITLE_ITEM_1',
//                 type: 'item',
//                 iconType: 'feather',
//                 icon: 'icon-file',
//                 key: 'menu-with-title.menu-with-title-item-1',
//                 submenu: []
//             },
//             {
//                 path: '/menu-2',
//                 title: 'Menu 2',
//                 translateKey: 'NAV.MENU_WITH_TITLE_ITEM_2',
//                 type: 'item',
//                 iconType: 'feather',
//                 icon: 'icon-file',
//                 key: 'menu-with-title.menu-with-title-item-2',
//                 submenu: []
//             },
//         ]
//     }
// ]

// const navWithSubMenu: NavMenu[] = [
//     {
//         path: '',
//         title: 'Nav with submenu',
//         translateKey: 'NAV.NAV_WITH_SUBMENU',
//         type: 'collapse',
//         iconType: 'feather',
//         icon: 'icon-align-left',
//         key: 'nav-wth-submenu',
//         submenu: [
//             {
//                 path: '/submenu-1',
//                 title: 'Submenu 1',
//                 translateKey: 'NAV.SUBMENU_1',
//                 type: 'item',
//                 iconType: 'feather',
//                 icon: 'icon-file',
//                 key: 'nav-wth-submenu.submenu-1',
//                 submenu: []
//             },
//             {
//                 path: '/submenu-2',
//                 title: 'Submenu 2',
//                 translateKey: 'NAV.SUBMENU_2',
//                 type: 'item',
//                 iconType: 'feather',
//                 icon: 'icon-file',
//                 key: 'nav-wth-submenu.submenu-2',
//                 submenu: []
//             },
//         ]
//     }
// ]

// const subMenuWithTitle: NavMenu[] = [
//     {
//         path: '',
//         title: 'Submenu with title',
//         translateKey: 'NAV.SUBMENU_WITH_TITLE',
//         type: 'title',
//         iconType: 'feather',
//         icon: 'icon-package',
//         key: 'submenu-with-title',
//         submenu: [
//             {
//                 path: '',
//                 title: 'Menu',
//                 translateKey: 'NAV.MENU',
//                 type: 'collapse',
//                 iconType: 'feather',
//                 icon: 'icon-box',
//                 key: 'submenu-with-title.menu',
//                 submenu : [
//                     {
//                         path: '/ui-elements/accordion',
//                         title: 'Submenu 1',
//                         translateKey: 'NAV.SUBMENU_1',
//                         type: 'item',
//                         iconType: '',
//                         icon: '',
//                         key: 'submenu-with-title.menu.submenu-1',
//                         submenu: []
//                     },
//                     {
//                         path: '/ui-elements/alert',
//                         title: 'Submenu 2',
//                         translateKey: 'NAV.SUBMENU_2',
//                         type: 'item',
//                         iconType: '',
//                         icon: '',
//                         key: 'submenu-with-title.menu.submenu-2',
//                         submenu: []
//                     }
//                 ],
//             },
//         ]
//     }
// ]

export const navConfiguration: NavMenu[] = [
    ...dashboard,
    ...items,
    ...customers,
    ...quotations,
    ...old_quotations,
    ...invoices,
    ...administration,
    // ...navWithSubMenu,
    // ...menuWithTitle,
    // ...subMenuWithTitle
]