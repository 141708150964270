<div>
  <div class="modal-body d-flex justify-content-between pb-0">
    <div class="d-flex">
      <div class="icon-block mt-2">
          <i
            class="fs-4 feather"
            [ngClass]="{
              'icon-thumbs-up text-primary':
                type === 'info' || type === 'success',
              'icon-alert-triangle text-danger': type === 'warning',
              'icon-thumbs-down text-warning': type === 'decline'
            }"
          ></i>
      </div>
      <div class="text-block ms-3">
        <div class="modal-title pull-left h5 my-1">{{ title }}</div>
        {{ message }}
      </div>
    </div>
    <div class="cross-block">
      <button
        type="button"
        class="btn-sm btn p-0 close pull-right border-0"
        aria-label="Close"
        (click)="bsModalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-footer border-0">
    <button
      type="button"
      class="btn btn-phoenix-secondary btn-sm"
      (click)="bsModalRef.hide()"
    >
      {{ closeBtnName ? closeBtnName : "Cancel" }}
    </button>
    <button
      type="button"
      class="btn btn-sm"
      (click)="onSubmit()"
      [ngClass]="{
        'btn-danger': type === 'warning' || type === 'decline',
        'btn-primary': type === 'info',
        'btn-success': type === 'success'
      }"
    >
      {{ submitBtnName ? submitBtnName : "Confirm" }}
    </button>
  </div>
</div>
