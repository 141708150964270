import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';
import { ForgotPassword, Login, LoginResponse, Organization, Register, User } from '../types/auth.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {

  /* Creating two private variables. The first one is an array of type Subscription. The second one is
  a string. */
  private unsubscribe: Subscription[] = [];
  private tokenName = `access-token`;

  // public fields
  currentUser$: Observable<User>;
  currentUserSubject: BehaviorSubject<User>;
  inviteUserSubject: BehaviorSubject<User>;

  /**
   * The currentUserValue function returns the currentUserSubject.value
   * @returns The currentUserSubject.value is being returned.
   */
  get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   * The currentUserSubject is a BehaviorSubject that is initialized with null.
   *
   * The currentUserValue is a setter that sets the current value of the currentUserSubject
   * @param {User} user - User - the user object that we want to store in the currentUserSubject
   */
  set currentUserValue(user: User) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private router: Router,
    private http: HttpService
  ) {
    /* Creating a new BehaviorSubject and setting it to an empty object. It is then creating an
    observable from the BehaviorSubject. It is then calling the getCurrentUser() function. */
    this.currentUserSubject = new BehaviorSubject<User>({} as any);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.inviteUserSubject = new BehaviorSubject<User>({} as any);
    this.getCurrentUser()
  }

  /**
   * If the login is successful, set the access token in local storage and then get the current user.
   * @param {Login} loginPayload - Login - this is the payload that is sent to the server.
   * @returns The result of the switchMap() operator.
   */
  login(loginPayload: Login): Observable<User> {
    return this.http.post('auth/login', loginPayload).pipe(
      map((auth: LoginResponse) => {
        const result = this.setAccessTokenInLocalStorage(auth);
        return result;
      }),
      switchMap(() => this.getCurrentUser()),
    );
  }

  /**
   * It takes a payload, sends it to the server, and then returns the current user
   * @param {Login} payload - Login
   * @returns The observable is returning the user object.
   */
  loginWithGoogle(payload: any): Observable<User> {
    return this.http.post(`auth/login/google`, payload).pipe(
      map((resp: any) => {
        const result = this.setAccessTokenInLocalStorage(resp);
        return result;
      }),
      switchMap(() => this.getCurrentUser()),
    );
  }

  /**
   * It removes the token from local storage and navigates to the login page
   */
  logout() {
    localStorage.removeItem(this.tokenName);
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  /**
   * "If the user is logged in, get the user's information from the server and store it in the
   * currentUserSubject. If the user is not logged in, log them out."
   *
   * The first thing we do is check if the user is logged in. If they are, we make a request to the
   * server to get the user's information. If the user is not logged in, we log them out
   * @returns The current user.
   */
  getCurrentUser(): Observable<User> {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return of(undefined);
    }

    return this.http.get(`auth/self`).pipe(
      map((user: User) => {
        if (user) {
          this.currentUserSubject.next(user);
        } else {
          this.logout();
        }
        return user;
      }),
    );
  }

  /**
   * It takes a user object, sends it to the server, and then returns the current user object.
   * @param {Register} user - Register - this is the user object that is being passed in from the
   * component.
   * @returns The user object.
   */
  registration(user: Register): Observable<User> {
    return this.http.post(`auth/register`, user).pipe(
      map((auth: LoginResponse) => {
        const result = this.setAccessTokenInLocalStorage(auth);
        return result;
      }),
      switchMap(() => this.getCurrentUser()),
    );
  }

  /**
   * It takes a payload of type ForgotPassword and returns an Observable of type any
   * @param {ForgotPassword} forgotPasswordPayload - ForgotPassword
   * @returns An observable of any type.
   */
  forgotPassword(forgotPasswordPayload: ForgotPassword): Observable<any> {
    return this.http.post(`auth/forgot-password`, forgotPasswordPayload)
  }

  /**
   * It takes a password and a token as arguments and sends a post request to the backend with the
   * password and token as the body.
   * </code>
   * @param {string} password - string
   * @param {string} token - The token that was sent to the user's email
   * @returns The return value is an Observable.
   */
  resetPassword(password: string, token: string) {
    return this.http.post(`auth/reset-password?token=${token}`, { password })
  }

  /**
   * If the auth object is not null and the auth object has a token property, then store the token in
   * local storage.
   * @param {LoginResponse} auth - LoginResponse
   * @returns The return value is a boolean.
   */
  setAccessTokenInLocalStorage(auth: LoginResponse): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.token) {
      localStorage.setItem(
        this.tokenName,
        auth.token
      );
      return true;
    }
    return false;
  }

  /**
   * If the access token is in local storage, return it, otherwise return undefined.
   * @returns The access token is being returned.
   */
  getAccessToken(): string {
    try {
      const accessToken = localStorage.getItem(this.tokenName);
      return accessToken || undefined
    } catch (error) {
      return undefined;
    }
  }

  /**
   * It takes a token as a parameter, creates a header with the token, and then sends a GET request to
   * the API.
   * </code>
   * @param {string} token - string - the token that was sent to the user's email
   */
  verifyInvite(token: string) {
    return this.http.get(`auth/invites?token=${token}`)
  }

  /**
   * It takes a token and a payload, creates a header with the token, and then sends a post request to
   * the server with the payload.
   * @param {string} token - string - the token that was sent to the user's email
   * @param {Register} payload - Register
   * @returns The response is a JSON object with the following properties:
   */
  registerInvitedUser(token: string, payload: Register) {
    return this.http.post(`auth/invites?token=${token}`, payload)
  }

  /**
   * For each subscription in the array of subscriptions, unsubscribe from it.
   */
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  /**
   * This function takes a payload of type User and returns an Observable of type User.
   * @param {User} payload - User
   * @returns The return value is an Observable.
   */
  updateProfile(payload: User) {
    return this.http.put(`auth/profile`, payload)
  }

  /**
   * It takes an object of type Organization and sends it to the server
   * @param {Organization} payload - Organization
   * @returns The return value is an Observable.
   */
  updateCompany(payload: Organization) {
    return this.http.put(`auth/organization`, payload)
  }

  /**
   * This function will return a response from the server that will tell us if the token is valid or
   * not.
   * @param {string} token - The token that was sent to the user's email address.
   * @returns The response from the server.
   */
  verifyResetPassword(token: string) {
    return this.http.get(`auth/reset-password?token=${token}`);
  }
}
