import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationBoxComponent } from './components/confirmation-box/confirmation-box.component';
import { SafePipe } from './utils/safe.pipe';

@NgModule({
    declarations: [
      ConfirmationBoxComponent,
      SafePipe
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        TranslateModule,
        SafePipe
    ],
  imports: [CommonModule],
})

export class SharedModule { }
