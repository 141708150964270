<div class="d-flex align-items-center mb-4">
  <div class="col">
    <h4>Old quotations</h4>
    <span>These quotation can not be edited, view purpose only </span>
  </div>
</div>

<div>
  <!-- <div class="bg-white p-3 border-bottom rounded"> -->
  <div class="d-flex justify-content-between">
    <div class="page-size align-items-center">
      <div class="input-affix">
        <i class="prefix-icon feather icon-search"></i>
        <input #search type="text" placeholder="Search quote by name" class="form-control" />
      </div>
    </div>
  </div>
</div>

<div class="table-responsive">
  <table class="table table-hover bg-white mt-3 rounded bg-white" [ngClass]="isLoading ? 'loading' : ''">
    <thead>
      <tr>
        <th>
          <span class="sort align-middle pe-5 cursor-pointer" scope="col">Quotation #</span>
        </th>
        <th>
          <span class="sort align-middle pe-5 cursor-pointer" scope="col" data-sort="date">Date</span>
        </th>
        <th>
          <span class="sort align-middle text-start cursor-pointer" scope="col" >Customer</span>
        </th>
        <th>
          <span  class="sort align-middle cursor-pointer" scope="col">Amount</span>
        </th>
        <th class="sort align-middle text-end" scope="col"></th>
      </tr>
    </thead>
    <tbody *ngIf="quotes?.length && !isLoading" class="list" id="customers-table-body">
      <tr class="hover-actions-trigger btn-reveal-trigger position-static" *ngFor="let quote of quotes; let i = index">
        <td class="email align-middle white-space-nowrap pe-5">
          <span class="text-primary fw-semi-bold text-800">
            {{ quote.quoteNumber }}</span>
        </td>
        <td class="total-orders align-middle white-space-nowrap fw-semi-bold text-start text-800">
          {{ this.quote.quoteDate | date : "dd-MM-yyyy" }}
        </td>
        <td class="total-orders align-middle white-space-nowrap fw-semi-bold text-start text-800">
          {{ quote.customerId[0].name }}
        </td>
        <td class="last-seen align-middle white-space-nowrap text-800">
          {{ quote.amount | currency : "INR" }}
        </td>
        <td class="align-middle white-space-nowrap fw-bold text-800 text-end">
          <a download target="_blank" [href]="quote.file">
            <button class="btn btn-secondary">
              <i class="feather icon-file-text"></i> View Quote
            </button>
          </a>
          <!-- Let's open quote in the modal - iframe with full width -->
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="isLoading" class="list">
      <tr>
        <td class="text-center white-space-nowrap text-end text-1000 p-3" colspan="7">Loading...</td>
      </tr>
    </tbody>
    <tbody *ngIf="!quotes?.length && !isLoading" class="list" id="customers-table-body">
      <tr>
        <td class="text-center white-space-nowrap text-end text-1000 p-3" colspan="7">
          There are no old quotations!
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="d-flex justify-content-between align-items-center" *ngIf="quotes?.length">
  <div class="d-flex align-items-center">
    <select class="form-select" style="width: 140px; height: 38px; border-width: 1px" (change)="changeLimit($event)">
      <option *ngFor="let pageSize of pageSizes" [value]="pageSize?.value" [selected]="
            pageSize?.value == this.QuoteListPreferences?.recordsPerPage
          ">
        {{ pageSize.label }}
      </option>
    </select>
    <p class="ms-2 mb-0 d-none d-sm-block me-3 fw-semi-bold text-900" data-list-info="data-list-info">
      Showing
      {{
      page * quoteListPreferences.recordsPerPage -
      quoteListPreferences.recordsPerPage +
      1
      }}
      to
      {{
      page * quoteListPreferences.recordsPerPage > totalResults
      ? totalResults
      : page * quoteListPreferences.recordsPerPage
      }}
      of {{ totalResults }} records
    </p>
  </div>
  <pagination [totalItems]="this.totalResults" [itemsPerPage]="quoteListPreferences.recordsPerPage" [(ngModel)]="page"
    [maxSize]="5" (pageChanged)="loadPage($event)"></pagination>
</div>