import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { HttpService } from '@app/shared/services/http.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, fromEvent } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'old-quotation-list',
  templateUrl: './old-quotation-list.component.html',
  styleUrls: ['./old-quotation-list.component.scss'],
})
export class OldQuotationListComponent implements OnInit, AfterViewInit {
  env: any = environment;
  pageSizes: any[] = [
    { value: '10', label: '10 / Page' },
    { value: '15', label: '15 / Page' },
    { value: '20', label: '20 / Page' },
    { value: '50', label: '50 / Page' },
  ];
  // [x: string]: any;
  quoteListPreferences = {
    recordsPerPage: 10,
    sortBy: '',
  };

  bsModalRef: any;
  constructor(
    private http: HttpService,
    private cdr: ChangeDetectorRef
  ) {}

  quotes!: any;
  page = 1;
  maxSize = 2;
  totalResults = 1;
  search: string = '';
  sort: string = '';
  isLoading!: boolean;
  // sort icon variables
  sortElements: Array<ElementRef> = [];
  @ViewChild('search') searchInput: ElementRef;

  @ViewChildren('ele') Ele!: QueryList<ElementRef>;
  tableColumns: any = {
    quoteDate: 'default',
    quoteNumber: 'default',
    customerName: 'default',
    total: 'default',
  };

  ngOnInit(): void {
    if (localStorage.getItem('oldQuoteListPreferences')) {
      this.quoteListPreferences = JSON.parse(
        localStorage.getItem('oldQuoteListPreferences')!
      );
    } else {
      localStorage.setItem(
        'oldQuoteListPreferences',
        JSON.stringify(this.quoteListPreferences)
      );
    }
    if (this.quoteListPreferences?.sortBy) {
      const column = this.quoteListPreferences?.sortBy.split(':');
      this.tableColumns[column[0]] = column[1];
    }
    this.getQuoteList();
  }

  ngAfterViewInit(): void {
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      debounceTime(350)
    ).subscribe((event) => {
      this.page = 1;
      this.searchQuote(event);
    });
  }

  ngAfterViewChecked(): void {
    this.sortElements = this.Ele.toArray();
  }

  getQuoteList() {
    this.isLoading = true;
    let path = 'old-quotation/';
    let query: any = {
      page: this.page,
      limit: this.quoteListPreferences.recordsPerPage,
    };

    if (this.quoteListPreferences.sortBy) {
      query = { ...query, sortBy: this.quoteListPreferences.sortBy };
    }

    if (this.search) {
      query = { ...query, search: this.search };
    }

    this.http.get(path, query).subscribe({
      next: (data: any) => {
        this.isLoading = false;
        this.quotes = data[0]?.results;
        this.totalResults = data[0]?.totalResults;
        this.page = data[0]?.page;
        this.cdr.detectChanges();
      },
      error: (err) => {
        this.isLoading = false;
        this.cdr.detectChanges();
      },
    });
  }

  loadPage(e: any) {
    this.page = e.page;
    this.getQuoteList();
  }

  sortBy(data: string, i: any) {
    //icon change
    this.sortElements.map((ele, index) => {
      if (index != i) ele.nativeElement.classList.remove('asc', 'desc');
    });
    //  toggle icon
    if (this.tableColumns[data] === 'default') {
      if (Object.keys(this.tableColumns).length > 0) {
        for (const [key, value] of Object.entries(this.tableColumns)) {
          if (value) this.tableColumns[key] = 'default';
        }
      }
      this.sortElements[i]?.nativeElement.classList.replace(
        'sortable',
        'sorted-asc'
      );
      this.tableColumns[data] = 'asc';
    } else if (this.tableColumns[data] === 'asc') {
      this.sortElements[i]?.nativeElement.classList.replace(
        'sorted-asc',
        'sorted-desc'
      );
      this.tableColumns[data] = 'desc';
    } else if (this.tableColumns[data] === 'desc') {
      this.sortElements[i]?.nativeElement.classList.replace(
        'sorted-desc',
        'sorted-asc'
      );
      this.tableColumns[data] = 'asc';
    }

    // this.sort = data + ':' + this.tableColumns[data];
    this.quoteListPreferences.sortBy = data + ':' + this.tableColumns[data];
    localStorage.setItem(
      'oldQuoteListPreferences',
      JSON.stringify(this.quoteListPreferences)
    );
    this.getQuoteList();
  }

  searchQuote(e: any) {
    this.search = e.target.value.trim();
    this.getQuoteList();
  }

  changeLimit(e: any) {
    this.quoteListPreferences.recordsPerPage = e.target.value;
    this.page = 1;
    localStorage.setItem(
      'oldQuoteListPreferences',
      JSON.stringify(this.quoteListPreferences)
    );
    this.getQuoteList();
  }
}
